import http from '../clients/Axios'

export default class PurchaseRecommendService {
  async export(supplier_id, periode) {
    if (!supplier_id) supplier_id = 0
    return http({
      url: `/purchase/recom/print?filter[supplier_id]=${supplier_id}&filter[periode]=${periode}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async get(supplier_id, periode) {
    if (!supplier_id) supplier_id = 0
    return await http.get(`/purchase/recom?filter[supplier_id]=${supplier_id}&filter[periode]=${periode}`)
  }
}
