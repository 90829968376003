<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Rekomendasi Pembelian</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pembelian / Rekomendasi Pembelian</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="mb-2">
        <div class="grid">
          <div class="col-12">
            <form>
              <div class="formgrid grid">
                <div class="field col-12">
                  <div class="font-bold">Filter</div>
                </div>
                <div class="field col-12 md:col-4">
                  <Dropdown
                    v-model="form.supplier"
                    :options="list_supplier"
                    optionValue="id"
                    optionLabel="nama"
                    placeholder="Pilih Supplier"
                    class="w-full"
                    :class="{
                      'p-invalid': v$.form.supplier.$invalid && submitted,
                    }"
                    filter
                    showClear
                  />
                  <small
                    v-if="
                      (v$.form.supplier.$invalid && submitted) ||
                      v$.form.supplier.$pending.$response
                    "
                    class="p-error"
                    >{{ v$.form.supplier.required.$message }}</small
                  >
                </div>
                <div class="field col-12 md:col-2">
                  <Dropdown
                    v-model="form.periode"
                    :options="list_periode"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Pilih Periode"
                    class="w-full"
                    filter
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <Button
                    :loading="isLoadingPreview"
                    class="p-button-success mr-2"
                    @click="onPreview(!v$.$invalid)"
                    type="button"
                    icon="pi pi-table"
                    label="Preview"
                  />
                  <Button
                    :loading="isLoadingExport"
                    @click="onExport(!v$.$invalid)"
                    type="button"
                    icon="pi pi-file-pdf"
                    label="Cetak"
                  />
                  <Button
                    type="reset"
                    icon="pi pi-refresh"
                    label="Reset"
                    class="p-button-outlined ml-2"
                    @click="resetForm"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <DataTable
        :value="items"
        :row-hover="true"
        :loading="isLoadingPreview"
        :paginator="false"
        :scrollable="true"
        :lazy="true"
        :totalRecords="totalRecords"
        filterDisplay="none"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
        class="p-datatable-sm"
      >
        <template #empty>
          <div class="text-center">Tidak ada data.</div>
        </template>
        <template #loading>
          <div class="text-center">Memuat data, harap tunggu...</div>
        </template>
        <Column
          field="nama_kategori"
          header="Kategori Obat"
          style="width: 100px"
        >
          <template #body="{ data }">
            {{ data.nama_kategori }}
          </template>
        </Column>
        <Column field="nama_obat" header="Nama Obat" style="width: 150px">
          <template #body="{ data }">
            {{ data.nama_obat }}
          </template>
        </Column>
        <Column
          field="penjualan_periode"
          :header="`Penjualan ${form.periode} hari`"
          style="flex-grow: 1; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ data.penjualan_periode }}
          </template>
        </Column>
        <Column
          field="penjualan_hari"
          header="Penjualan per hari"
          style="flex-grow: 1; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ data.penjualan_hari }}
          </template>
        </Column>
        <Column
          field="rekomendasi_stok"
          header="Rekomendasi Stok"
          style="flex-grow: 1; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ data.rekomendasi_stok }}
          </template>
        </Column>
        <Column
          field="stock"
          header="Stock"
          style="flex-grow: 1; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ data.stock }}
          </template>
        </Column>
        <Column
          field="rekomendasi_beli"
          header="Rekomendasi Beli"
          style="flex-grow: 1; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ data.rekomendasi_beli }}
          </template>
        </Column>
      </DataTable>
    </div>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownload"
      />
      <Button type="button" label="Print" icon="pi pi-print" @click="onPrint" />
    </template>
  </Dialog>
</template>

<script>
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import SupplierService from '@/services/SupplierService'
import PurchaseRecommendService from '@/services/PurchaseRecommendService'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { formatCurrency } from '@/helpers'
import { saveAs } from 'file-saver'

export default {
  name: 'RekomendasiPembelian',
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Hotkey,
  },
  data() {
    return {
      supplierService: null,
      purchaseRecommendService: null,
      isLoadingPreview: false,
      isLoadingExport: false,
      dialogViewer: false,
      faktur: 0,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      submitted: false,
      items: [],
      list_supplier: [],
      list_periode: [],
      form: {
        periode: 30,
        supplier: null,
      },
      totalRecords: 0,
    }
  },
  created() {
    this.purchaseRecommendService = new PurchaseRecommendService()
    this.supplierService = new SupplierService()
  },
  mounted() {
    this.list_periode = [
      {
        label: '30 Hari',
        value: 30,
      },
      {
        label: '60 Hari',
        value: 60,
      },
      {
        label: '90 Hari',
        value: 90,
      },
    ]
    this.supplierService
      .get('?sort=nama')
      .then((res) => {
        this.list_supplier = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Preview Rekomendasi Pembelian', this)
      })
  },
  methods: {
    onPreview(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      const supplierId = this.form.supplier
      const periode = this.form.periode
      this.isLoadingPreview = true
      this.purchaseRecommendService
        .get(supplierId, periode)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoadingPreview = false
        })
        .catch((err) => {
          errorHandler(err, 'Preview Rekomendasi Pembelian', this)
        })
        .finally(() => (this.isLoadingPreview = false))
    },
    onExport(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      const supplierId = this.form.supplier
      const periode = this.form.periode
      this.isLoadingExport = true
      this.purchaseRecommendService
        .export(supplierId, periode)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'rekomendasi-pembelian.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Export Rekomendasi Pembelian', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onPrint() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownload() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    resetForm() {
      this.submitted = false
      this.form.periode = 30
      this.form.supplier = null
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddBankAccount()
      }
    },
  },
  validations() {
    return {
      form: {
        supplier: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>
